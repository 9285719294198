import { EventsApiClient } from '@homeexchange-legacy/events-api-client-js';
import { createContext } from 'react';
import Iterable from './iterable';
import useGTM from '../components/analytics/useGTM';

export const AnalyticsContext = createContext();

const GTMDataTypes = { eventData: 'event_data', pageData: 'page_data', ecommerce: 'ecommerce' };

export default class Analytics {
    constructor(accessTokenProvider, options = {}, user = null) {
        this.accessTokenProvider = accessTokenProvider;
        this.eventsClient = new EventsApiClient(accessTokenProvider, Object.assign({}, options));
        this.track = this.track.bind(this);
        this.user = user;
    }

    // event name mapping between old and new tracking plan
    static mapping = {
        'search.new': 'New search',
        'search.updated': 'Updated filters',
        'search.paginate': 'Search paginate',
        'alert.created': 'New alert',
        'conversation.created': 'contacted-member',
        'exchange.preapproved': 'approved-exchange',
        'exchange.finalized': 'finalized-exchange',
        'user.picture.uploaded': 'Profile picture uploaded',
        'home.picture.uploaded': 'Home picture uploaded',
        'home.completed': 'House 100%',
        'user.completed': 'Profil 100%'
    };

    static trackingPlan = {
        PageView: {
            GTM: {
                name: 'page_view',
                params: [{ name: 'user_data' }, { name: 'page_data' }, { name: 'event_data' }]
            }
        },
        'Account created': {
            spinalCord: {
                functionName: 'userCreated'
            },
            GTM: {
                name: 'Account created',
                params: [{ name: 'source', optional: true }, { name: 'userEmail' }, { name: 'user_data' }]
            }
        },
        UserCreated: {
            GTM: {
                name: 'user_created',
                params: [{ name: 'user_data' }, { name: 'page_data' }, { name: 'event_data' }]
            }
        },
        SignIn: {
            GTM: {
                name: 'sign_in',
                params: [{ name: 'user_data' }, { name: 'page_data' }, { name: 'event_data' }]
            }
        },
        verification: {
            GTM: {
                name: 'verification',
                params: [{ name: 'user_data' }, { name: 'page_data' }, { name: 'event_data' }]
            }
        },
        SponsorshipCodeSend: {
            GTM: {
                name: 'sponsorship_code_send',
                params: [{ name: 'user_data' }, { name: 'page_data' }, { name: 'event_data' }]
            }
        },
        SponsorshipReceived: {
            GTM: {
                name: 'sponsorship_received',
                params: [{ name: 'user_data' }, { name: 'page_data' }, { name: 'event_data' }]
            }
        },
        MixpanelAddFavorites: {
            GTM: {
                name: 'MixpanelAddFavorites',
                params: [{ name: 'userStatus' }, { name: 'user_data' }]
            }
        },
        MixpanelMessageSent: {
            GTM: {
                name: 'MixpanelMessageSent',
                params: [{ name: 'userStatus' }, { name: 'user_data' }]
            }
        },
        MixpanelExchangeFinalized: {
            GTM: {
                name: 'MixpanelExchangeFinalized',
                params: [{ name: 'userStatus' }, { name: 'user_data' }]
            }
        },
        MixpanelExchangePreApproved: {
            GTM: {
                name: 'MixpanelExchangePreApproved',
                params: [{ name: 'userStatus' }, { name: 'user_data' }]
            }
        },
        MixpanelHomeQualified: {
            GTM: {
                name: 'MixpanelHomeQualified',
                params: [{ name: 'userStatus' }, { name: 'user_data' }]
            }
        },
        MixpanelSubscriptionPurchased: {
            GTM: {
                name: 'MixpanelSubscriptionPurchased',
                params: [{ name: 'userStatus' }, { name: 'user_data' }]
            }
        },
        SleeknoteEvent: {
            GTM: {
                name: 'SleeknoteEvent',
                params: [{ name: 'currency' }, { name: 'status' }, { name: 'user_data' }]
            }
        },

        'Signup email verified': {
            GTM: {
                name: 'Signup email verified',
                params: [{ name: 'source', optional: true }, { name: 'user_data' }]
            }
        },

        'HEC Account created': {
            GTM: {
                name: 'HEC Account created',
                params: [{ name: 'user_data' }]
            }
        },
        'Profil 100%': {
            GTM: {
                name: 'Profile Completed',
                params: [{ name: 'user_data' }]
            }
        },
        'Home init': {
            GTM: {
                name: 'Home init',
                params: [{ name: 'user_data' }]
            }
        },
        'HEC Home init': {
            GTM: {
                name: 'HEC Home init',
                params: [{ name: 'user_data' }]
            }
        },
        HEC_Purchase: {
            GTM: {
                name: 'HEC_Purchase',
                params: [{ name: 'user_data' }]
            }
        },
        'Home created': {
            spinalCord: {
                functionName: 'homeCreated',
                params: [{ name: 'homeId' }]
            },
            GTM: {
                name: 'Home created',
                params: [{ name: 'user_data' }]
            },
            // already implemented
            iterable: {
                name: 'HomeCreated',
                params: [{ name: 'homeId' }, { name: 'city' }, { name: 'state' }, { name: 'country' }]
            }
        },
        'HEC Home created': {
            GTM: {
                name: 'HEC Home created',
                params: [{ name: 'user_data' }]
            }
        },
        'Home deleted': {
            // already implemented
            iterable: {
                name: 'HomeDeleted',
                params: [{ name: 'homeId' }]
            }
        },
        HomeOffline: {
            iterable: {
                name: 'HomeOffline',
                params: [{ name: 'homeId' }]
            }
        },
        HomeOnline: {
            iterable: {
                name: 'HomeOnline',
                params: [{ name: 'homeId' }]
            }
        },
        HomeUpdated: {
            iterable: {
                name: 'HomeUpdated',
                params: [{ name: 'completionRate' }, { name: 'homeId' }]
            }
        },
        HomePhotosUpdated: {
            iterable: {
                name: 'HomePhotosUpdated',
                params: [{ name: 'homeId' }]
            }
        },
        HomeVerificationRequestInitiated: {
            iterable: {
                name: 'HomeVerificationRequestInitiated',
                params: [{ name: 'homeId' }]
            }
        },
        'Home 80%': {
            GTM: {
                name: 'Home 80%',
                params: [{ name: 'user_data' }]
            }
        },
        'House 100%': {
            GTM: {
                name: 'Home Completed',
                params: [{ name: 'user_data' }]
            }
        },
        'Home neighborhood': {
            GTM: {
                name: 'Home neighborhood',
                params: [{ name: 'user_data' }]
            }
        },
        'contacted-member': {
            spinalCord: {
                functionName: 'conversationCreated',
                params: [{ name: 'conversationId' }]
            },
            GTM: {
                name: 'Conversation created',
                params: [{ name: 'user_data' }]
            }
        },
        'finalized-exchange': {
            spinalCord: {
                functionName: 'exchangeFinalized',
                params: [{ name: 'exchangeId' }]
            },
            GTM: {
                name: 'Exchange finalized',
                params: [{ name: 'user_data' }]
            }
        },
        exchange_status: {
            GTM: {
                name: 'exchange_status',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }, { name: 'page_data' }]
            }
        },
        conversation_created: {
            GTM: {
                name: 'conversation_created',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        'dashboard-viewed': {
            spinalCord: {
                functionName: 'dashboardViewed',
                params: [{ name: 'homes' }]
            }
        },
        'page-viewed': {
            GTM: {
                name: 'Virtual Pageviewed',
                params: [{ name: 'url' }, { name: 'user_data' }]
            },
            spinalCord: {
                functionName: 'pageViewed'
            }
        },
        'preferred-destination-created': {
            GTM: {
                name: 'New Preferred Destination',
                params: [{ name: 'origin' }, { name: 'user_data' }]
            }
        },
        'homes-searched': {
            spinalCord: {
                functionName: 'homesSearched',
                params: [{ name: 'id' }, { name: 'query' }]
            }
        },
        HomesSearchedForIterable: {
            iterable: {
                name: 'HomesSearched',
                params: [
                    { name: 'travellers' },
                    { name: 'lastSearchExecutedAt' },
                    { name: 'lastSearchEndOn' },
                    { name: 'lastSearchStartOn' },
                    { name: 'destinationLastSearch' },
                    { name: 'urlLastSearch' },
                    { name: 'lastMinuteFilter' },
                    { name: 'reverseSearhFilter' },
                    { name: 'exchangeTypeFilter' }
                ]
            }
        },
        'home-search-results-viewed': {
            spinalCord: {
                functionName: 'homeSearchResultsViewed',
                params: [{ name: 'homes' }, { name: 'queryId' }]
            }
        },
        purchase: {
            GTM: {
                name: 'purchase',
                params: [
                    { name: 'event_data' },
                    { name: 'user_data' },
                    { name: 'page_data' },
                    { name: 'ecommerce' }
                ]
            }
        },
        Purchase: {
            GTM: {
                name: 'Purchase',
                params: [
                    { name: 'has_subscription' },
                    { name: 'has_pay_as_you_go' },
                    { name: 'has_gp' },
                    { name: 'total' },
                    { name: 'order_id', optional: true },
                    { name: 'currency', optional: true },
                    { name: 'ecommerce', optional: true },
                    { name: 'user_data' }
                ]
            }
        },
        'First message': {
            GTM: {
                name: 'First message',
                params: [{ name: 'user_data' }]
            }
        },
        'home-detail-viewed': {
            spinalCord: {
                functionName: 'homeDetailViewed',
                params: [{ name: 'homeId' }]
            }
        },
        'Location Completed': {
            GTM: {
                name: 'Location Completed',
                params: [{ name: 'user_data' }]
            }
        },
        Login: {
            iterable: {
                name: 'Login',
                params: [{ name: 'device' }]
            }
        },
        UpdateCalendar: {
            iterable: {
                name: 'UpdateCalendar',
                params: [{ name: 'homeId' }]
            }
        },
        SubscriptionAutoRenewDisabled: {
            iterable: {
                name: 'SubscriptionAutoRenewDisabled'
            }
        },
        SubscriptionAutoRenewEnabled: {
            iterable: {
                name: 'SubscriptionAutoRenewEnabled'
            }
        },
        UpdateProfile: {
            iterable: {
                name: 'UpdateProfile',
                params: [{ name: 'completionRate' }]
            }
        },
        ViewedPaymentPage: {
            iterable: {
                name: 'ViewedPaymentPage',
                params: [{ name: 'pageName' }]
            }
        },
        MyPlanPageViewed: {
            iterable: {
                name: 'MyPlanPageViewed',
                params: [{ name: 'pageName' }]
            }
        },
        SignupFunnel: {
            spinalCord: {
                functionName: 'experimentAssigned',
                params: [{ name: 'experimentId' }, { name: 'variantId' }]
            }
        },
        AbandonedFinalizationAsGuest: {
            iterable: {
                name: 'AbandonedFinalizationAsGuest',
                params: [
                    { name: 'exchangeId' },
                    { name: 'exchangeType' },
                    { name: 'exchangeStartOn' },
                    { name: 'exchangeEndOn' },
                    { name: 'exchangeSumGp' },
                    { name: 'nightCount' },
                    { name: 'hostId' },
                    { name: 'hostName' },
                    { name: 'hostHomeId' },
                    { name: 'hostHomeName' },
                    { name: 'hostHomeLocation' },
                    { name: 'hostHomePictureUrl' },
                    { name: 'conversationId' }
                ]
            }
        },
        AbandonedPreapprovalAsHost: {
            iterable: {
                name: 'AbandonedPreapprovalAsHost',
                params: [
                    { name: 'exchangeId' },
                    { name: 'exchangeType' },
                    { name: 'exchangeStartOn' },
                    { name: 'exchangeEndOn' },
                    { name: 'exchangeSumGp' },
                    { name: 'nightCount' },
                    { name: 'guestId' },
                    { name: 'guestName' },
                    { name: 'hostHomeId' },
                    { name: 'hostHomeName' },
                    { name: 'hostHomeLocation' }
                ]
            }
        },
        SearchMap: {
            GTM: {
                name: 'map',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        PerformedSearch: {
            GTM: {
                name: 'performed_a_search',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },

        SearchResults: {
            GTM: {
                name: 'search_results',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        HomeEdition: {
            GTM: {
                name: 'home edit',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        home_view: {
            GTM: {
                name: 'home_view',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        HandleFavorites: {
            GTM: {
                name: 'favorites',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        submit_lead_form_collection: {
            GTM: {
                name: 'submit_lead_form_collection',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }, { name: 'page_data' }]
            }
        },
        modal: {
            GTM: {
                name: 'modal',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }, { name: 'page_data' }]
            }
        },
        button_blocker: {
            GTM: {
                name: 'button_blocker',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        button: {
            GTM: {
                name: 'button',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }, { name: 'page_data' }]
            }
        },
        modal_blocker: {
            GTM: {
                name: 'modal_blocker',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        funnel_start: {
            GTM: {
                name: 'funnel_start',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        funnel_progress: {
            GTM: {
                name: 'funnel_progress',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        funnel_completed: {
            GTM: {
                name: 'funnel_completed',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        home_filling_pannel: {
            GTM: {
                name: 'home_filling_pannel',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        banner_collection_footer: {
            GTM: {
                name: 'banner_collection_footer',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        },
        banner: {
            GTM: {
                name: 'banner',
                params: [{ name: 'event_data' }, { name: 'user_data' }, { name: 'page_data' }]
            }
        },
        header_menu_home: {
            GTM: {
                name: 'header_menu_home',
                dataType: 'event_data',
                params: [{ name: 'event_data' }, { name: 'user_data' }]
            }
        }
    };

    track(eventName, properties, callback = null) {
        let mappedEvent = eventName;

        if (Analytics.mapping.hasOwnProperty(eventName)) {
            mappedEvent = Analytics.mapping[eventName];
        }

        if (Analytics.trackingPlan.hasOwnProperty(mappedEvent)) {
            return Promise.all(
                // eslint-disable-next-line no-unused-vars
                Object.entries(Analytics.trackingPlan[mappedEvent]).map(([destination, options]) => {
                    switch (destination) {
                        case 'spinalCord': {
                            // Tracking on Spinal Cord
                            return this.trackSpinalCord(mappedEvent, properties);
                        }
                        case 'GTM':
                            // Tracking on Google Tag Manager
                            return Analytics.trackGTM(mappedEvent, properties, callback);
                        case 'iterable':
                            // Tracking on iterable
                            return Analytics.trackIterable(mappedEvent, properties);
                        default:
                            return Promise.reject(new Error(`Unknown destination "${destination}"`));
                    }
                })
            );
        }
        return Promise.reject(new Error(`Event "${mappedEvent}" does not exists in tracking plan.`));
    }

    trackSpinalCord(eventName, properties = {}) {
        if (!(eventName in Analytics.trackingPlan)) {
            return Promise.reject(new Error(`Event "${eventName}" does not exists in tracking plan.`));
        }
        if (!('spinalCord' in Analytics.trackingPlan[eventName])) {
            return Promise.reject(
                new Error(`No SpinalCord options for event "${eventName}" in tracking plan.`)
            );
        }

        const { functionName, params = [] } = Analytics.trackingPlan[eventName].spinalCord;
        if (!(functionName in this.eventsClient)) {
            return Promise.reject(new Error(`Methode "${functionName}" in EventsApiClient does not exists.`));
        }

        const args = params.map((param) => {
            if (
                properties.hasOwnProperty(param.name) &&
                properties[param.name] !== null &&
                properties[param.name] !== undefined
            ) {
                return properties[param.name];
            }
            return null;
        });

        if (args.some((arg) => arg === null)) {
            return Promise.reject(new Error(`Some event params are missing.`));
        }

        return this.eventsClient[functionName](...args).catch((error) => {
            console.warn(`Error while calling ${functionName} on EventsAPI`);
            throw new Error(
                `TrackSpinalCord - Error while sending event ${eventName}. Error Message: ${JSON.stringify({
                    name: error.name,
                    message: error.message
                })}`
            );
        });
    }

    static trackGTM(eventName, properties = {}, callback = null) {
        /**
         * In this static trackGTM method get the stateful running instance of analytics to fetch its user data
         */
        const analytics = window.analytics;
        properties = {
            ...(properties ?? {}),
            user_data: analytics.getEventUserDataObject()
        };

        if (!(eventName in Analytics.trackingPlan)) {
            return Promise.reject(new Error(`Event "${eventName}" does not exists in tracking plan.`));
        }

        if (!('GTM' in Analytics.trackingPlan[eventName])) {
            return Promise.reject(new Error(`No GTM options for event "${eventName}" in tracking plan.`));
        }

        if (window && window.dataLayer) {
            const { name, dataType, params = [] } = Analytics.trackingPlan[eventName].GTM;

            // Build params
            const details = {};
            params.forEach((param) => {
                if (
                    properties.hasOwnProperty(param.name) &&
                    properties[param.name] !== null &&
                    properties[param.name] !== undefined
                ) {
                    details[param.name] = properties[param.name];
                } else if (!param.optional) {
                    console.warn(
                        `Unable to find property ${param.name} in event properties`,
                        eventName,
                        properties
                    );
                    const error = new Error(
                        `TrackGTM - Unable to find property ${
                            param.name
                        } in event properties. Event:${eventName}, Available props: ${JSON.stringify(
                            properties
                        )}`
                    );
                    if (window.Sentry) window.Sentry.captureException(error);
                }
            });

            if (callback) {
                details.eventCallback = callback;
                details.eventTimeout = 2000;

                // GTM is not loaded
                if (!window.google_tag_manager) {
                    setTimeout(() => {
                        // GTM still not loaded, call the callback right now
                        if (!window.google_tag_manager) {
                            callback();
                        }
                    }, 2000);
                }
            }

            if (dataType) {
                Analytics.flushGTMData(dataType);
            }

            window.dataLayer.push({ event: name, ...details });

            return Promise.resolve();
        } else if (callback) {
            // GTM is not loaded, callback won't be called
            callback();
            return Promise.resolve();
        }
    }

    static trackIterable(eventName, properties) {
        // for properties object, either email or userId is mandatory
        if (!(eventName in Analytics.trackingPlan)) {
            return Promise.reject(new Error(`Event "${eventName}" does not exists in tracking plan.`));
        }

        if (!('iterable' in Analytics.trackingPlan[eventName])) {
            return Promise.reject(
                new Error(`No iterable options for event "${eventName}" in tracking plan.`)
            );
        }

        const { params } = Analytics.trackingPlan[eventName].iterable;

        // build args
        const argsObj = {
            eventName: Analytics.trackingPlan[eventName].iterable.name,
            dataFields: {}
        };
        // Email must be passed in to identify the user.
        if (!properties.email) {
            console.warn('Iterable Tracking - Missing email');
            return Promise.reject(new Error(`Email must be passed in to identify the user`));
        }

        argsObj.email = properties.email;

        if (params) {
            params.forEach((param) => {
                if (
                    properties.hasOwnProperty(param.name) &&
                    properties[param.name] !== null &&
                    properties[param.name] !== undefined
                ) {
                    argsObj.dataFields[param.name] = properties[param.name];
                } else {
                    console.warn(
                        `Unable to find property ${param.name} in event properties`,
                        eventName,
                        properties
                    );
                }
            });
        }

        // send event to iterable
        const iterable = Iterable.getIterableInstance();
        const { setEmail } = iterable.getIterableAuthObject();

        // return Iterable.track(argsObj);
        setEmail(properties.email)
            .then(() => Iterable.track(argsObj))
            .catch((error) => console.error('[ITERABLE USER IDENTIFICATION]:', error));
    }

    static flushGTMData(dataType) {
        switch (dataType) {
            case GTMDataTypes.eventData:
                window.dataLayer.push({ event_data: null });
                break;
            case GTMDataTypes.pageData:
                window.dataLayer.push({ page_data: null });
                break;
            case GTMDataTypes.ecommerce:
                window.dataLayer.push({ ecommerce: null });
                break;
            default:
                break;
        }
    }

    setUser(user) {
        this.user = user;
    }

    getEventUserDataObject() {
        const { fetchUserData } = useGTM(this.user);
        return fetchUserData();
    }

    static trackHomeCompletion = (currentHome, receivedHome) => {
        if (!currentHome || !receivedHome) return;
        const firstReached80Completion =
            !currentHome.get('eighty_completion_date') && !!receivedHome.get('eighty_completion_date');
        const firstReached100Completion =
            !currentHome.get('full_completion_date') && !!receivedHome.get('full_completion_date');

        if (firstReached80Completion) {
            window.analytics.track('Home 80%');
        }
        if (firstReached100Completion) {
            window.analytics.track('House 100%');
        }
    };
}
