var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Analytics from '../analytics';
// ================================= HOME VIEW TRACKING =============================================
export var BUTTON_BLOCKER_EVENT = 'button_blocker';
export var MODAL_BLOCKER_EVENT = 'modal_blocker';
export var HOME_VIEW_EVENT = 'home_view';
export var HOME_VIEW_DATA_AREA = 'homeview';
export var HOME_FUNNEL_DATA_AREA = 'home_funnel';
export var trackHomeview = function (eventName, text, event) {
    Analytics.trackGTM(eventName, {
        event_data: {
            action: 'click',
            area: HOME_VIEW_DATA_AREA,
            text: text
        }
    });
    if (event) {
        event.preventDefault();
        var anchorTarget = event.target;
        window.location.href = anchorTarget.href;
    }
};
export var trackButtonClick = function (area, text, collection) {
    return Analytics.trackGTM('button', {
        event_data: {
            action: 'click',
            area: area,
            text: text,
            collection: collection
        }
    });
};
//================================SEARCH TRACKING=================================
export var trackPerformedSearch = function (searchParam, searchValue) {
    return Analytics.trackGTM('PerformedSearch', {
        event_data: __assign(__assign({ action: 'onSearchRequest', area: 'search' }, (searchParam && { search_parameter: searchParam })), (searchValue && { search_value: searchValue }))
    });
};
export var searchResultsData = {
    text: {
        homeCard: 'home_card',
        saveDestination: 'save_this_destination',
        moreFilters: 'more_filters',
        searchBanner: 'become_member_search-banner',
        dualSearch: 'dual_search',
        noResults: 'no_results'
    },
    searchParam: {
        saveDestination: 'save_this_destination',
        sortBy: 'sort_by',
        noResults: 'save_this_destination_no_results'
    }
};
export var trackSearchResults = function (text, searchParam, searchValue) {
    return Analytics.trackGTM('SearchResults', {
        event_data: __assign(__assign(__assign({ action: 'click', area: 'search' }, (text && { text: text })), (searchParam && { search_parameter: searchParam })), (searchValue && { search_value: searchValue }))
    });
};
// ==============================FILLING PANNEL TRACKING==================================
export var HOME_FILLING_PANNEL_EVENT_NAME = 'home_filling_pannel';
export var HOME_FILLING_PANNEL_EVENT_DATA_AREA = 'homepage';
export var HOME_FILLING_PANNEL_EVENT_DATA_TEXT = {
    homeCreation: 'home_creation',
    homeQualification: 'home_qualification',
    homeQualificationCalendarNoAvailabilities: 'home_qualification_calendar_no_availabilities',
    calendarUpdateNoAvailabilities: 'calendar_update_no_availabilities',
    calendarNotUpdatedSinceFourMonths: 'calendar_not_updated_since_4_months'
};
export var trackFillingPanel = function (text) {
    return Analytics.trackGTM(HOME_FILLING_PANNEL_EVENT_NAME, {
        event_data: {
            action: 'click',
            area: HOME_FILLING_PANNEL_EVENT_DATA_AREA,
            text: text
        }
    });
};
//==============================HEADER MENU TRACKING=============================
export var HEADER_MENU_TRACKING_DATA_TEXT = {
    addHome: 'add_a_home',
    completeHomeFirst: 'complete_your_home_first_time',
    completeHome: 'complete_your_home',
    publishHome: 'publish_your_home',
    addAvailabilities: 'add_availabilities_in_the_coming_months',
    updateAvailabilities: 'are_your_availabilities_up_to_date',
    homeCompleted: 'home_and_calendar_completed'
};
export var trackHeaderMenu = function (text) {
    Analytics.trackGTM('header_menu_home', {
        event_data: {
            action: 'click',
            area: 'header',
            text: text
        }
    });
};
var RegularFunnelsEvents = /** @class */ (function () {
    function RegularFunnelsEvents() {
    }
    RegularFunnelsEvents.funnelStart = function (name, step) {
        return Analytics.trackGTM('funnel_start', {
            event_data: { funnel_name: name, funnel_step: step, area: HOME_FUNNEL_DATA_AREA }
        });
    };
    RegularFunnelsEvents.funnelProgress = function (name, step) {
        return Analytics.trackGTM('funnel_progress', {
            event_data: { funnel_name: name, funnel_step: step, area: HOME_FUNNEL_DATA_AREA }
        });
    };
    RegularFunnelsEvents.funnelCompleted = function (name, step) {
        return Analytics.trackGTM('funnel_completed', {
            event_data: { funnel_name: name, funnel_step: step, area: HOME_FUNNEL_DATA_AREA }
        });
    };
    RegularFunnelsEvents.funnelModalView = function (name) {
        var step = stepFromUrl();
        return Analytics.trackGTM('modal', {
            event_data: __assign({ funnel_name: name, text: 'modal_save_and_resume_qualification_funnel', area: HOME_FUNNEL_DATA_AREA }, (step && { funnel_step: step }))
        });
    };
    RegularFunnelsEvents.funnelModalContinue = function (name) {
        var step = stepFromUrl();
        return Analytics.trackGTM('button', {
            event_data: __assign({ funnel_name: name, text: 'go_to_dashboard_qualification_funnel', area: HOME_FUNNEL_DATA_AREA }, (step && { funnel_step: step }))
        });
    };
    RegularFunnelsEvents.funnelModalCancel = function (name) {
        var step = stepFromUrl();
        return Analytics.trackGTM('button', {
            event_data: __assign({ funnel_name: name, text: 'modal_keep_editing_qualification_funnelcancel', area: HOME_FUNNEL_DATA_AREA }, (step && { funnel_step: step }))
        });
    };
    RegularFunnelsEvents.HOME_CREATION_FUNNEL = {
        name: 'home_creation_regular',
        step: {
            listing: 'home_creation_type_of_listing',
            address: 'home_creation_address',
            surroundingTags: 'home_creation_surrounding_tags',
            size: 'home_creation_size',
            beds: 'home_creation_beds',
            amenities: 'home_creation_amenities',
            description: 'home_creation_description',
            calendar: 'home_creation_calendar',
            photos: 'home_creation_photos',
            registration: 'home_creation_registration_number',
            final: 'home_creation_sumup_finalscreen'
        },
        cta: {
            continue: 'continue',
            skip: 'skip',
            back: 'back'
        },
        byStep: {
            step1: 'listing',
            step2: 'address',
            step3: 'surroundingTags',
            step4: 'size',
            step5: 'beds',
            step6: 'amenities',
            step7: 'description',
            step8: 'calendar',
            step9: 'photos',
            step10: 'registration',
            step11: 'final'
        }
    };
    return RegularFunnelsEvents;
}());
export { RegularFunnelsEvents };
function stepFromUrl() {
    var urlParts = window.location.pathname.split('/');
    var step = urlParts[urlParts.length - 2];
    if (step.startsWith('step')) {
        return RegularFunnelsEvents.HOME_CREATION_FUNNEL.byStep[step];
    }
    return false;
}
export var trackVerificationModalView = function () {
    var eventData = {
        area: 'homeview',
        action: 'view',
        text: 'verification_collection',
        collection: true
    };
    Analytics.trackGTM('modal', { event_data: eventData });
};
export var trackVerificationButtonClick = function () {
    var eventData = {
        area: 'homeview',
        action: 'click',
        text: 'complete_verification',
        collection: true
    };
    Analytics.trackGTM('button', { event_data: eventData });
};
