import Api from '../api/Api';
import i18n from '../i18n';
import Model from './Model';
import User from './User';

export default class Home extends Model {
    static INSURANCE_NEEDED = 1;
    static INSURANCE_NOT_NEEDED = 0;

    static TYPE_HOME = 1;
    static TYPE_APARTMENT = 2;
    static TYPE_OTHER = 3;

    static STATUS_DELETED = -10;
    static STATUS_OFFLINE = -5;
    static STATUS_PENDING = 0;
    static STATUS_UNMODERATE = 1;
    static STATUS_ONLINE = 5;
    static HOME_COMPLETED = 100;

    static STATUS_NOT_VERIFIED = 0;
    static STATUS_VERIFIED = 1;

    static RESIDENCE_PRIMARY_TEMP = 1;
    static RESIDENCE_SECONDARY_TEMP = 2;
    static RESIDENCE_PRIMARY = 3;
    static RESIDENCE_SECONDARY = 4;

    static ENOUGH_COMPLETION_RATE = 80;
    static HOME_QUALIFIED = 80;
    static MINIMUM_FILLING_TO_CONTACT = 80;
    static MAX_GP_FILLING = 100;

    static CONTACT_NOT_ALLOWED_ON_UNAVAILABLE = 0;
    static CONTACT_ALLOWED_ON_UNAVAILABLE = 1;

    static MAX_DEPOSIT_VALUE = 5000;

    static METRIC_M2 = 'm2';
    static METRIC_SQFT = 'sqft';

    static MIN_IMAGES = 5;

    static MIN_GP_EARNED = 50;
    static MAX_GP_EARNED = 250;

    static SHARED_PART_WHOLE_UNIT = 1;
    static SHARED_PART_PRIVATE_ROOM = 2;

    static PRIORITY_LOCATION = 3;
    static PRIORITY_FEATURES = 7;
    static PRIORITY_PRIVACY = 9;

    static FEATURES_LABEL = {
        outdoor: {
            1: 'feature-outdoor-garden',
            2: 'feature-outdoor-swimming-pool',
            4: 'feature-outdoor-bbq',
            16: 'feature-outdoor-tennis-court',
            32: 'feature-outdoor-ping-pong-table',
            64: 'feature-outdoor-balcony-terrace'
        },
        practical: {
            1: 'feature-practical-disabled-access',
            2: 'feature-practical-baby-gear',
            4: 'feature-practical-dishwasher',
            8: 'feature-practical-dryer',
            16: 'feature-practical-washing-machine',
            32: 'feature-practical-microwave-oven',
            128: 'feature-practical-freezer',
            256: 'feature-practical-oven',
            512: 'feature-practical-fridge',
            1024: 'feature-practical-bathtub',
            2048: 'feature-practical-heating-system',
            4096: 'feature-practical-electric-car-plug'
        },
        multimedia: {
            1: 'feature-multimedia-tv',
            2: 'feature-multimedia-computer',
            4: 'feature-multimedia-internet',
            8: 'feature-multimedia-wifi',
            16: 'feature-multimedia-home-cinema',
            64: 'feature-multimedia-satellite-cable',
            128: 'feature-multimedia-phone',
            512: 'feature-multimedia-video-game-console',
            1024: 'feature-multimedia-smart-tv'
        },
        various: {
            1: 'feature-various-ac',
            2: 'feature-various-elevator',
            4: 'feature-various-parking-space',
            8: 'feature-various-piano',
            16: 'feature-various-jacuzzi',
            32: 'feature-various-sauna',
            64: 'feature-various-fireplace',
            128: 'feature-various-gym',
            256: 'feature-various-pool-table'
        },
        others: {
            1: 'feature-others-basic',
            2: 'feature-others-simple',
            4: 'feature-others-cosy',
            8: 'feature-others-comfortable',
            16: 'feature-others-luxurious'
        },
        transportation: {
            1: 'feature-transportation-car',
            2: 'feature-transportation-bicycle',
            4: 'feature-transportation-motor-scooter',
            8: 'feature-transportation-motorcycle',
            16: 'feature-transportation-motorboat',
            32: 'feature-transportation-sailboat',
            64: 'feature-transportation-electric-car'
        },
        personal: {
            1: 'feature-personal-doorman',
            2: 'feature-personal-cleaning-person',
            4: 'feature-personal-baby-sitter',
            8: 'feature-personal-cook',
            16: 'feature-personal-guide'
        },
        rule: {
            1: 'feature-rule-smokers-welcome',
            2: 'feature-rule-pets-welcome',
            4: 'feature-rule-children-welcome'
        },
        favor: {
            1: 'feature-favor-dog',
            2: 'feature-favor-cat',
            4: 'feature-favor-plants'
        },
        kids: {
            1: 'feature-kids-kids-toys',
            2: 'feature-kids-kids-playground',
            4: 'feature-kids-baby-gear',
            8: 'feature-kids-secured-swimming-pool',
            16: 'baby_bed'
        },
        eco: {
            1: 'feature-eco-renewable-energy-provider',
            2: 'feature-eco-low-consumption-machines',
            4: 'feature-eco-motor-selective-waste-sorting',
            8: 'feature-eco-vegetable-garden',
            16: 'feature-eco-public-transit-access',
            32: 'feature-eco-solar-panels'
        },
        remote: {
            1: 'feature-remote-high-speed-connection',
            2: 'feature-remote-dedicated-work-space'
        }
    };

    static STATUS_UNFLAGGED = null;
    static STATUS_ELIGIBLE = 2;
    static STATUS_NOT_ELIGIBLE = -1;

    completionEarnings = {
        address: 5,
        type: 5,
        location_type: 5,
        feature: 5,
        beds: 5,
        good_feature: 15,
        good_place: 5,
        size: 5,
        bedroom_nb: 5,
        bathroom_nb: 5,
        title: 5,
        residence_type: 5,
        calendar: 5,
        photo1: 25,
        photo2: 20,
        photo3: 15,
        photo4: 10,
        photo5: 5
    };

    get defaults() {
        return {
            descriptions: [],
            detail: {},
            feature: {},
            location: {},
            translated_admins: {},
            user: new User()
        };
    }

    constructor(attributes) {
        super(attributes, {
            mapping: {
                guestpoint_per_night: 'guestpoint',
                is_insurance_required: 'insurance',
                localized_admins: 'translated_admins',
                rating: 'global_rating'
            }
        });
    }

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('user') && _.isObject(attributes.user)) {
            if (attributes.user instanceof User === false) {
                attributes.user = new User(attributes.user);
            }

            // Mapping for rating and number of exchanges
            if (attributes.user.has('number_exchange')) {
                attributes.exchange_count = attributes.user.get('number_exchange');
            }
        }

        if (attributes.hasOwnProperty('feature') && _.isObject(attributes.feature)) {
            attributes.features = Home.parseBinaryFeatures(attributes.feature);
        }

        // we need to update size with localized_size to match size with size_unit
        if (
            attributes.hasOwnProperty('detail') &&
            _.isObject(attributes.detail) &&
            attributes.detail.hasOwnProperty('localized_size')
        ) {
            attributes.detail.size = Number(attributes.detail.localized_size);
        }

        return attributes;
    }

    static parseBinaryFeatures(features) {
        const homeFeatures = {
            outdoor: [],
            practical: [],
            multimedia: [],
            various: [],
            others: [],
            transportation: [],
            personal: [],
            rule: [],
            favor: [],
            kids: [],
            eco: [],
            remote: []
        };

        for (const indexLabel in features) {
            const binary = features[indexLabel];
            for (const index in this.FEATURES_LABEL[indexLabel]) {
                if (binary & index) {
                    const label = this.FEATURES_LABEL[indexLabel][index];
                    homeFeatures[indexLabel].push(label);
                }
            }
        }

        return homeFeatures;
    }

    static parseIdType(type) {
        switch (type) {
            case 1:
                return 'home';
            case 2:
                return 'flat';
            case 3:
                return 'other';
            default:
                throw new Error(`Unknow type ID (${type})`);
        }
    }

    description() {
        if (Array.isArray(this.get('descriptions')) == false) {
            return;
        }
        let description = this.get('descriptions').find((desc) => desc.locale == i18n.language);
        if (_.isUndefined(description) && !_.isUndefined(this.get('user').get('locale'))) {
            description = this.get('descriptions').find(
                (desc) => desc.locale == this.get('user').get('locale'),
                this
            );
        }
        return description;
    }

    getDefaultHomeTitle() {
        if (this.get('shared_part') === Home.SHARED_PART_PRIVATE_ROOM) {
            return i18n.t('home:private_room.default_title', {
                firstname: this.get('user').get('first_name')
            });
        } else {
            return i18n.t('home:title_default', {
                firstname: this.get('user').get('first_name')
            });
        }
    }

    isCompleted() {
        return this.get('completion_rate') === Home.HOME_COMPLETED;
    }

    isQualified() {
        return this.get('completion_rate') >= Home.HOME_QUALIFIED;
    }

    getMinNights() {
        const minNights = this.get('min_nights');
        return minNights !== 0 ? minNights : null;
    }

    title() {
        const defaultValue = this.getDefaultHomeTitle();
        const title = _.result(this.description(), 'title', defaultValue);

        if (_.isEmpty(title)) {
            return defaultValue;
        }

        return title;
    }

    getDescriptionAttr(attr) {
        let description = this.get('descriptions').find(
            (desc) => desc.locale == i18n.language && !_.isUndefined(desc) && !_.isEmpty(_.result(desc, attr))
        );
        if (!description) {
            description = this.get('descriptions').find(
                (desc) => !_.isUndefined(desc) && !_.isEmpty(_.result(desc, attr))
            );
        }
        return _.result(description, attr);
    }

    // eslint-disable-next-line camelcase
    good_place() {
        return _.result(this.description(), 'good_place');
    }

    // eslint-disable-next-line camelcase
    good_feature() {
        return _.result(this.description(), 'good_feature');
    }

    other() {
        return _.result(this.description(), 'other');
    }

    translatedAdmins() {
        if (Array.isArray(this.get('translated_admins'))) {
            return this.get('translated_admins').find((translation) => translation.locale == i18n.language);
        }
        return this.get('translated_admins');
    }

    firstAdmin() {
        let admins = this.translatedAdmins();
        admins = _.omit(admins, ['admin4', 'country', 'locale']);
        admins = _.compact(admins);
        return _.last(admins);
    }

    country() {
        const admins = this.translatedAdmins();
        if (admins) {
            return admins.country;
        }
    }

    location() {
        const firstAdmin = this.firstAdmin();
        const country = this.country();
        if (firstAdmin && firstAdmin !== country) {
            return `${firstAdmin}, ${country}`;
        } else {
            return country;
        }
    }

    pictures(width, height, smartResize, borders) {
        if (this.has('images') && !_.isEmpty(this.get('images'))) {
            const images = _.sortBy(this.get('images'), (image) => image.order_display);
            return images.map((image) => {
                return {
                    src: `${image.cdn_link ?? image.link}?${$.param(
                        Api.Image.getImageParams(width, height, smartResize, borders, image.rotate)
                    )}`,
                    order_display: image.order_display
                };
            });
        } else if (this.has('pictures') && !_.isEmpty(this.get('pictures'))) {
            const images = this.get('pictures');
            return images.map((id) => Api.Image.getImageUrl(id, width, height, smartResize, borders));
        }
    }

    picture(width, height, smartResize, borders) {
        if (this.has('images') && !_.isEmpty(this.get('images'))) {
            const images = _.sortBy(this.get('images'), (image) => image.order_display);
            const image = _.first(images);
            if (image.cdn_link) {
                return `${image.cdn_link}?${$.param(
                    Api.Image.getImageParams(width, height, smartResize, borders, image.rotate)
                )}`;
            } else {
                return `${image.link}?${$.param(
                    Api.Image.getImageParams(width, height, smartResize, borders, image.rotate)
                )}`;
            }
        } else if (this.has('pictures') && !_.isEmpty(this.get('pictures'))) {
            const id = _.first(this.get('pictures'));
            return Api.Image.getImageUrl(id, width, height, smartResize, borders);
        }
    }

    isFavorite(homeFavorites) {
        return Api.Favorites.hasFavorite(this.id, homeFavorites);
    }

    toggleContactAllowed() {
        return this.get('contact_allowed');
    }

    isPublished() {
        return this.get('status') == Home.STATUS_ONLINE;
    }

    isOffline() {
        return this.get('status') == Home.STATUS_OFFLINE;
    }

    isDeleted() {
        return this.get('status') == Home.STATUS_DELETED;
    }

    isVerified() {
        return this.get('is_verified') == Home.STATUS_VERIFIED;
    }

    isCollection() {
        return this.get('is_he_collection');
    }

    getCapacity() {
        if (!this.get('detail')) {
            return 0;
        }

        return (
            (parseInt(this.get('detail').single_bed, 10) || 0) +
            (parseInt(this.get('detail').double_bed, 10) || 0) * 2 +
            (parseInt(this.get('detail').big_double_bed, 10) || 0) * 2 +
            (parseInt(this.get('detail').children_bed, 10) || 0) +
            (parseInt(this.get('detail').baby_bed, 10) || 0) +
            (parseInt(this.get('detail').single_bed_up, 10) || 0) +
            (parseInt(this.get('detail').double_bed_up, 10) || 0) * 2 +
            (parseInt(this.get('detail').children_bed_up, 10) || 0)
        );
    }

    isCompletionAchieved(fieldName) {
        switch (fieldName) {
            case 'address':
            case 'type':
                return Boolean(this.has(fieldName) && this.get(fieldName));
            case 'location_type':
            case 'residence_type':
            case 'bathroom_nb':
            case 'size':
                return Boolean(this.get('detail') && this.get('detail')[fieldName]);
            case 'bedroom_nb':
                // Allow 0 (studios)
                return Boolean(this.get('detail') && Number.isInteger(this.get('detail').bedroom_nb));
            case 'title':
            case 'good_feature':
            case 'good_place':
                return Boolean(this.getDescriptionAttr(fieldName));
            case 'beds':
                return this.getCapacity() > 0;
            case 'photo1':
                return this.has('images') && this.get('images').length >= 1;
            case 'photo2':
                return this.has('images') && this.get('images').length >= 2;
            case 'photo3':
                return this.has('images') && this.get('images').length >= 3;
            case 'photo4':
                return this.has('images') && this.get('images').length >= 4;
            case 'photo5':
                return this.has('images') && this.get('images').length >= 5;
            case 'feature':
                if (this.has('feature') && this.get('feature')) {
                    const f = this.get('feature');
                    return f.multimedia + f.practical + f.outdoor + f.various > 0;
                }
                return false;
            default:
                return false;
        }
    }

    getFunnelStep() {
        // check if the home was completed once in the past
        if (this.get('eighty_completion_date')) {
            return false;
        }
        // no surroundings
        if (this.get('feature')?.surrounding === 0) {
            return 'step3';
        }
        // size of the home
        if (this.get('detail')?.size === 0) {
            return 'step4';
        }
        // no beds ?
        if (
            this.get('detail')?.single_bed === 0 &&
            this.get('detail')?.double_bed === 0 &&
            this.get('detail')?.big_double_bed === 0 &&
            this.get('detail')?.single_bed_up === 0 &&
            this.get('detail')?.double_bed_up === 0
        ) {
            return 'step5';
        }
        // no amenities
        if (this.getAmenitiesSum() === 0) {
            return 'step6';
        }

        // no description
        if (
            this.get('descriptions')?.length === 0 ||
            this.get('descriptions')[0].good_feature === null ||
            this.get('descriptions')[0].good_place === null
        ) {
            return 'step7';
        }

        // no calendar
        if (this.get('calendar_updated_at') === null) {
            return 'step8';
        }

        // no pictures
        if (this.get('images')?.length < 5) {
            return 'step9';
        }

        return false;
    }

    getAmenitiesSum() {
        return (
            this.get('features')?.outdoor +
            this.get('features')?.practical +
            this.get('features')?.multimedia +
            this.get('features')?.various +
            this.get('features')?.others +
            this.get('features')?.transportation +
            this.get('features')?.personal +
            this.get('features')?.rule +
            this.get('features')?.favor +
            this.get('features')?.kids +
            this.get('features')?.eco +
            this.get('features')?.remote
        );
    }

    getEditUrl() {
        const funnelStep = this.getFunnelStep();
        if (funnelStep) {
            return i18n.t('home:home.funnel.url', { step: funnelStep, id: this.id });
        } else {
            return i18n.t('home:home.edit.url', { id: this.id });
        }
    }
}
